import { FC, PropsWithChildren, useCallback, useContext } from "react";
import { FavoriteEventsContext, FeedContext } from "@finbackoffice/site-core";
import classnames from "classnames";
import { useRouter } from "next/router";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import Translate from "components/base/translate/Translate";
import { FeedTypeEnum, useGetCurrentFeedType, useScrollToSelectedSport } from "hooks";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import Sport from "../../pages/sport/base/sport/Sport";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import styles from "./sports-layout.module.sass";

const Favorites: FC = () => {
    const feed = useGetCurrentFeedType();
    const { liveTopLeaguesGamesCount } = useContext(FeedContext);
    const { favLiveLeaguesGameCount, favPrematchLeaguesGameCount } =
        useContext(FavoriteEventsContext);
    return (
        <span className={classnames(styles.favorites, styles.active)}>
            <Svg
                src="/common/mobile/base-icons/favourites.svg"
                wrapper="span"
                className="svg-favorite-icon"
            />
            Favorites
            <span className={styles.gamesCount}>
                {feed === FeedTypeEnum.live && liveTopLeaguesGamesCount + favLiveLeaguesGameCount}
                {feed === FeedTypeEnum.prematch && favPrematchLeaguesGameCount}
            </span>
        </span>
    );
};

interface IProps {
    sports: ISportFeedResponse[];
    activeSportId?: number;
    onSportClick?: (sportId: number) => void;
    showGamesCount?: boolean;
    variant?: "default" | "widget";
}

const SportsLayout: FC<PropsWithChildren<IProps>> = ({
    sports,
    children,
    activeSportId,
    onSportClick,
    showGamesCount = true,
    variant = "default",
}) => {
    const feed = useGetCurrentFeedType();
    const router = useRouter();
    const { liveTopLeagues } = useContext(FeedContext);
    const { favEvents, favPrematchLeagues } = useContext(FavoriteEventsContext);
    const { containerRef } = useScrollToSelectedSport(activeSportId ?? null, sports);
    const showFavorites =
        router.pathname.includes(`/${RouteNames.LIVE}/${RouteNames.TOP}`) ||
        router.pathname.includes(`/${RouteNames.PREMATCH}/${RouteNames.FAVORITES}`);

    const hasFavorite = useCallback(
        (id: number) => {
            if (feed === FeedTypeEnum.live) {
                return !!favEvents.find((event) => event.sport_id === id) || false;
            }

            if (feed === FeedTypeEnum.prematch) {
                return !!favPrematchLeagues.find((event) => event.sport_id === id) || false;
            }

            return false;
        },
        [favEvents, favPrematchLeagues, feed],
    );

    return (
        <>
            {!!sports.length ? (
                <FadeInAnimation>
                    <div
                        className={styles.container}
                        ref={containerRef}
                        data-testid="sportsbook-live-container">
                        {showFavorites && <Favorites />}
                        {sports.map((sport) => (
                            <Sport
                                variant={variant === "widget" ? "button" : "link"}
                                key={sport.id}
                                sport={sport}
                                active={sport.id === activeSportId}
                                hasFavorite={
                                    hasFavorite(sport.id) ||
                                    !!liveTopLeagues.find(
                                        (tournament) => tournament.sport_id === sport.id,
                                    )
                                }
                                onClickHandler={onSportClick}
                                showGamesCount={showGamesCount}
                            />
                        ))}
                    </div>
                </FadeInAnimation>
            ) : (
                <div className={classnames("no-game", styles.noGame)}>
                    <Translate tid="no_games" />
                </div>
            )}
            {children}
        </>
    );
};

export default SportsLayout;
