import { FC, ReactNode, useCallback, useContext, useMemo } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { FavoriteEventsContext, usePrematchUpcomingWidgetConfig } from "@finbackoffice/site-core";
import { TranslationScopes } from "@finbackoffice/enums";
import { HoursRange } from "@finbackoffice/websocket-client";
import Translate from "components/base/translate/Translate";
import ActiveLink from "components/base/active-link/ActiveLink";
import Button from "components/base/button/Button";
import { Svg } from "components/base/svg/Svg";
import { PrematchContext } from "contexts";
import { RouteNames } from "utils/constants";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import styles from "./prematch-header.module.sass";

type TimeFilterOptions = {
    label: ReactNode;
    value: HoursRange;
};

const routeTopLeagues = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.TOP}`;
const routeUpcoming = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.UPCOMING}`;
const routeAll = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.ALL}`;
const routeFavorites = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.FAVORITES}`;

type IProps = {
    setHours?: (val: HoursRange) => void;
    hours?: HoursRange;
};

const PrematchHeader: FC<IProps> = ({ setHours, hours }) => {
    const router = useRouter();
    const { favPrematchLeaguesGameCount } = useContext(FavoriteEventsContext);
    const { setHoursRange, hoursRange } = useContext(PrematchContext);
    const prematchUpcomingWidgetConfig = usePrematchUpcomingWidgetConfig();

    const handleSelectFilter = useCallback(
        (value: HoursRange) => () => {
            if (!!setHours) {
                if (hours === value) {
                    setHours(HoursRange.ALL);
                } else {
                    setHours(value);
                }
            } else {
                if (hoursRange === value) {
                    setHoursRange(HoursRange.ALL);
                } else {
                    setHoursRange(value);
                }
            }

            if ([routeFavorites, routeUpcoming].some((route) => router.pathname.includes(route))) {
                router.push(routeAll);
            }
        },
        [hours, hoursRange, router, setHours, setHoursRange],
    );

    const handleResetTimeFilter = useCallback(() => {
        if (hoursRange) {
            setHoursRange(HoursRange.ALL);
        }
    }, [hoursRange, setHoursRange]);

    const handleFavoriteClick = () => {
        handleResetTimeFilter();
        router.push(routeFavorites);
    };

    const timeFiltersMemo: TimeFilterOptions[] = useMemo(
        () => [
            {
                label: (
                    <Translate
                        tid="prematch_24HoursFilter"
                        namespace={TranslationScopes.PreMatch}
                    />
                ),
                value: HoursRange.TWENTY_FOUR,
            },
            {
                label: (
                    <Translate tid="prematch_6HoursFilter" namespace={TranslationScopes.PreMatch} />
                ),
                value: HoursRange.SIX,
            },
        ],
        [],
    );

    const renderFilter = useCallback(
        (filter: TimeFilterOptions) => (
            <button
                key={filter.value}
                type="button"
                className={classnames(styles.timefilter, styles.timefilter_option, {
                    [styles.active]:
                        (!!setHours && hours === filter.value) ||
                        (!router.pathname.includes(routeUpcoming) && hoursRange === filter.value),
                })}
                onClick={handleSelectFilter(filter.value)}>
                {filter.label}
            </button>
        ),
        [setHours, hours, router.pathname, hoursRange, handleSelectFilter],
    );

    return (
        <FadeInAnimation>
            <div className={styles.prematchHeader}>
                <div className={styles.prematchFilter}>
                    <ActiveLink
                        href={routeTopLeagues}
                        includes={routeTopLeagues}
                        activeClassName={styles.active}
                        className={styles.timefilter}
                        onClick={handleResetTimeFilter}>
                        <Translate
                            tid="prematch_topLeagues"
                            namespace={TranslationScopes.PreMatch}
                        />
                    </ActiveLink>
                    <ActiveLink
                        href={routeUpcoming}
                        includes={routeUpcoming}
                        activeClassName={styles.activeAll}
                        className={styles.timefilter}
                        onClick={() =>
                            !!prematchUpcomingWidgetConfig &&
                            setHoursRange(prematchUpcomingWidgetConfig?.timeRange)
                        }>
                        <Translate
                            tid="prematch_upcoming_matches"
                            namespace={TranslationScopes.PreMatch}
                        />
                    </ActiveLink>
                    <ActiveLink
                        href={routeAll}
                        includes={routeAll}
                        forceActive={router.pathname.includes(
                            `/${RouteNames.PREMATCH}/${RouteNames.FAVORITES}`,
                        )}
                        activeClassName={styles.activeAll}
                        className={styles.timefilter}
                        onClick={handleResetTimeFilter}>
                        <Translate
                            tid="prematch_allMatches"
                            namespace={TranslationScopes.PreMatch}
                        />
                    </ActiveLink>
                    <div className={styles.timefilterOptions}>
                        {timeFiltersMemo.map(renderFilter)}
                    </div>
                    <Button
                        type="button"
                        disabled={favPrematchLeaguesGameCount === 0}
                        className={classnames(styles.favoritesTab, {
                            [styles.active]: router.pathname.includes(routeFavorites),
                        })}
                        onClick={handleFavoriteClick}>
                        <span className={styles.favoritesCount}>{favPrematchLeaguesGameCount}</span>
                        <Svg
                            src="/common/mobile/base-icons/favourites.svg"
                            wrapper="span"
                            className={styles.favoritesStar}
                        />
                    </Button>
                </div>
            </div>
        </FadeInAnimation>
    );
};

export default PrematchHeader;
